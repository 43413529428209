import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Typography, Box, Card, CardMedia, Dialog, DialogTitle, CardContent, DialogContent, IconButton, Divider, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';
import MediaItem from '../components/posts/mediaitem';
import CloseIcon from '@mui/icons-material/Close';
import PComments from '../components/mods/postcomments';

const GET_VIDEOS = gql`
  query {
    videos {
      _id
      Title
      description
      videourl
      summary
      cover
    }
  }
`;

const ScrollableContainer = styled(Box)({
  display: 'flex',
  overflowX: 'scroll',
  padding: '4px',
  gap: '16px',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '-ms-overflow-style': 'none',  /* IE and Edge */
  'scrollbar-width': 'none',  /* Firefox */
});

const StoryCard = styled(Card)({
  width: '150px',
  height: 'auto',
  flex: '0 0 auto',
  overflow: 'hidden',
  cursor: 'pointer',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  color: '#352536',
  borderRadius: '1px',
});

function DailyGrind({post}) {
  const { loading, error, data } = useQuery(GET_VIDEOS);
  const [selectedVideo, setSelectedVideo] = useState(null);

  if (loading) return <div></div>;
  if (error) return <div>Error fetching data</div>;

  const handleCardClick = (video) => {
    setSelectedVideo(video); // Pass the entire video object
  };

  const handleClose = () => {
    setSelectedVideo(null);
  };

  return (
    <div>
     <ScrollableContainer>
  {data.videos.map((video) => (
    <StoryCard key={video._id} onClick={() => handleCardClick(video)}
    sx={{
      objectFit: 'contain',  // Ensures the entire image fits within the container
      width: '80%',         // Make sure it spans the full width of the container
      borderRadius: 2,       // Optional: add border-radius if needed for rounded corners
      backgroundImage: `url(${video.cover})`, // Set background image
      backgroundSize: 'cover', // Cover the entire card
      backgroundPosition: 'center', // Center the image
             // Set a fixed height for the card
     aspectRatio: '16/9',   // Maintain a 16:9 aspect ratio

    }}
    >
     
    </StoryCard>
  ))}
</ScrollableContainer>


      <Dialog open={!!selectedVideo} onClose={handleClose} fullScreen>
        {selectedVideo && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <IconButton edge="end" color="inherit" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                {selectedVideo.Title}
              </DialogTitle>
            </Box>
            <DialogContent>
              {selectedVideo.videourl && (
                <MediaItem item={selectedVideo.videourl} title={selectedVideo.Title} />
              )}
               
              <Box sx={{ marginTop: 1, zIndex: 1400 }}>
              <PComments post={selectedVideo} />
                <Card sx={{ marginTop: 0, padding: 0 }}>
                  <Divider />
                  <CardContent sx={{ padding: 1 }}>
                    {/* Accordion for Summary */}
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="summary-content"
                        id="summary-header"
                      >
                        <Typography variant="body2">
                          {selectedVideo.summary.split('\n').slice(0, 3).join(' ')}...
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="body2">
                          {selectedVideo.summary}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Typography variant="body2" color="text.secondary">
                      {selectedVideo.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </DialogContent>
          </>
        )}
      </Dialog>
      <Box sx={{ marginTop: 1 }}>
      <Divider />
      </Box>
    </div>
  );
}

export default DailyGrind;
