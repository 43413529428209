import React, { useState, useEffect, useContext , useCallback} from 'react';
import { Typography, Box, Button, Grid, Container, Paper, useTheme, useMediaQuery, Drawer, Chip, Avatar, CardMedia, Icon,
  List, ListItem, ListItemText, Divider,
  Dialog,
  
 } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import Header from '../components/nav/mobilefarmer';
import MobileTop from '../components/nav/mobiletop';
import DeskMenu from '../components/nav/deskmenu';
import LeftBar from '../components/nav/leftbar';
import AiGen2 from '../components/finance/adds/aifeeds';
import ProductsDisplay from './desk/koffiemenu';
import Featured from '../components/finance/adds/featured';
import SandwichCard from '../components/finance/adds/popadd';
import AudioPlayerComponent from '../components/mods/morningshow';
import LatestPost from '../components/posts/latest';
import coffeecoin from '../images/kcoin.png';
import MiniShow from '../components/mods/mini-mornignshow';
import KTabs from '../components/nav/tabular';
import { useBeanBag } from '../context/beanBagContext';
import Progress from '../components/mods/progress';
import BeanCount from '../components/mods/beancount';
import { UserContext } from '../context/usercontext';
import Toe from '../components/nav/bottom';
import { useRef } from 'react';
import { useBag } from '../context/BagContext';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Barista from '../components/tests/barista';
import koffielogo from '../images/kcoin.png';
import { useQuery, gql, useLazyQuery } from '@apollo/client';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { IconButton } from '@mui/material';
import Badge from '@mui/material/Badge';
import Wallet from '../constants/wallet';
import { useNavigate } from 'react-router-dom';
import { ArrowForward, Check, Close, CommentOutlined, FeedbackOutlined, ListAltOutlined, LiveHelpOutlined, LocalCafeOutlined, PublicOutlined, ShoppingBagOutlined } from '@mui/icons-material';
import { animated, to, useSpring } from 'react-spring';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import { useMenuDrawer } from '../components/nav/profilemenu';
import Rewards from '../components/mods/beanrewards';
import ListItemIcon from '@mui/material/ListItemIcon';
import BeanReport from '../components/finance/prods/beanreport';
import Grow from '@mui/material/Grow';
import RightMenu from '../components/nav/rightbar';
import MainSt from './mainstreet';
import DoIT from '../components/profile/doit';
import GoalComponent from '../components/profile/GoalComponent';
import MianMobileMenu from '../components/nav/mainmobile';
import ConnectButton from '../blockchain/wbutton';
import { Web3ModalContext } from '../constants/Web3ModalProvider';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ChecklistRtlOutlinedIcon from '@mui/icons-material/ChecklistRtlOutlined';
import KoffieCalc from '../components/finance/koffiecalc';
import Logic from '../components/tests/logic';
import Display from '../components/profile/settings/displaymode';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Cloud from '@mui/icons-material/Cloud';
import ContentCut from '@mui/icons-material/ContentCut';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import LogoutButton from '../components/profile/logout';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ReceiptOutlined from '@mui/icons-material/ReceiptOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import HistoryEduOutlined from '@mui/icons-material/HistoryEduOutlined';

const BeanIcon = () => {
  return (
    <img src='https://storage.googleapis.com/app_darkendimg/assets/beanomic.png' alt="koffie" width={25} />
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Grow
      ref={ref}
      {...props}
      timeout={{
        enter: 700, // Duration for the grow-in effect
        exit: 500,  // Duration for the shrink-out effect
      }}
      easing={{
        enter: 'cubic-bezier(0.4, 0, 0.2, 1)', // Custom easing function
        exit: 'cubic-bezier(0.4, 0, 0.2, 1)',
      }}
    />
  );
});

const kcoin = coffeecoin;

const useStyles = makeStyles((theme) => ({
 boxContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    borderRadius: 5,
    boxShadow: 10,
    position: 'relative', // Add position relative for absolute positioning of ribbon
  },
  logo: {
    borderRadius: 0,
    margin: '0 auto',
    display: 'block',
    width: 50,
    height: 50,
    
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%', // Ensure the wrapper takes the full width
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom:   theme.spacing(2),
  },
  ribbon: {
    position: 'absolute',
    bottom: 0, // Adjust the position of the ribbon vertically
    left: 20, // Adjust the position of the ribbon horizontally
    backgroundColor: '#96ff00', // Adjust the background color of the ribbon
    padding: theme.spacing(0.05),
    borderRadius: '5px 5px 5px 0px', // Adjust border radius for the ribbon
    borderWidth: 1, // Adjust border width
    borderStyle: 'solid', // Adjust border style
    borderColor: '#fff', // Adjust border color
  },
  ribbonText: {
    color: 'black', // Adjust the text color of the ribbon
    fontWeight: 900, // Adjust the font weight of the ribbon text
    fontSize: 10, // Adjust the font size of the ribbon text
  },
  hideScrollbar: {
    overflowY: 'auto',
    scrollbarWidth: 'none', // For Firefox
    '-ms-overflow-style': 'none', // For Internet Explorer and Edge
    '&::-webkit-scrollbar': {
      display: 'none', // For Chrome, Safari, and Opera
    },
  },
  menuBox: {
  
    padding: 2,
    display: 'flex',
    width: '100%',
    margin: 'auto',
    justifyContent: 'space-around',
    marginTop: 20,
    
    
   
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  menuItem: {
    fontSize: 16,
    fontWeight: 500,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  logoContainer: {
    paddingLeft: theme.spacing(0),
    paddingTop: theme.spacing(2),
  },
  walletContainer: {
    paddingRight: theme.spacing(5),
  },
  menuTransition: {
    transition: 'fade 0.5s',
  },

  
}));

export const GET_CART = gql`
  query GetCart($userID: ObjectId!) {
    carts(query: { userID: $userID, isPaid: false }) {
      userID
      beanies
      isPaid
      productId
      category
      name
      _id
      price
      imageurl
      quantity
      options {
        size
        color
      }
    }
  }
`;

export const GET_USER = gql`   
  query Userprof ($_id: ObjectId!)  {
    userprof (query: {_id: $_id}) {
      flavorprofile
      Nickname
      tasteProfile
      description
      name
      purchaseLink
      Koffie_story
      usertype
      _id
      country
      beanCount 
      imageurl
      profileBuilt
      redeemed
      waitlist
      cashapp
      cashappid
      zelle
      zelleid
      payout
    }
  }
`;

const AppLayout = ({ children, selectedTabIndex, handleTabChange }) => {
  const { user , fetchUser} = useContext(UserContext);
 
  const classes = useStyles();
  const theme = useTheme();
  const { account, connect } = useContext(Web3ModalContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerOpen1, setDrawerOpen1] = useState(false);
  const [drawerOpen2, setDrawerOpen2] = useState(false);
  const [drawerOpen3, setDrawerOpen3] = useState(false);
  const [drawerOpen4, setDrawerOpen4] = useState(false);
  const [drawerOpen5, setDrawerOpen5] = useState(false);
  const [drawerOpen6, setDrawerOpen6] = useState(false);
  const [drawerOpen7, setDrawerOpen7] = useState(false);
  const { itemCount1 } = useBeanBag();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { itemsInBag , setItemsInBag, } = useBag();
  const [triggerFetch, setTriggerFetch] = useState(false);


  const { data: userData, loading: userLoading, error: userError } = useQuery(GET_USER, {
    fetchPolicy: 'cache-and-network',
    variables: { _id: user.id },
    skip: !user.id,
  });
  console.log("userData:", userData);

  useEffect(() => {
    if (userData && userData.users) {
      fetchUser(userData.users[0]);
    }
  }
  , [userData, fetchUser]);

  //get current user profile image
 

  const styles = useSpring({
    from: { scale: 1 },
    to: async (next) => {
      while (true) {
        await next({ scale: 1.2 });
        await next({ scale: 1 });
      }
    },
    config: { duration: 2000, easing: t => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t },
  });

 //get image fromurl GET_USER query


 
  const { data: cartData, loading: cartLoading, error: cartError } = useQuery(GET_CART, {
    fetchPolicy: 'cache-and-network',
    variables: { userID: user.id , },
    skip: !user.id,
  });
  console.log("cartData:", cartData);

  useEffect(() => {
    if (cartData && cartData.carts) {
      const newItemsInBag = cartData.carts.map((item) => {
        return {
          name: item.name,
          price: item.price,
          imageurl: item.imageurl,
          farmer: item.farmer,
          _id: item._id,
          quantity: item.quantity,
          isPaid: item.isPaid,
          options: item.options,
          category: item.category,
        };
      });
      setItemsInBag(newItemsInBag);
    }
  }, [cartData, ]);

  //fileter cart items that are Koffie and output the number of items
  const koffieItems = itemsInBag.filter((item) => item.category === 'Koffie');

  //list number of items in cart that are Koffie
  console.log("koffieItems:", koffieItems.length);


  const [value, setValue] = useState('recents');
 
  const { itemCount } = useBag();
  
  const handlelogoClick = () => {
    navigate('');
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [openFeedback, setOpenFeedback] = useState(false);

  const handleFeedback = () => {
    setOpenFeedback(true);
  };

  const handleCloseFeedback = () => {
    setOpenFeedback(false);
  };

  const handleLogoClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const thelogo = (
    <img
      src={koffielogo}
      alt="logo"
      width="60"
      height="60"
      onClick={handleLogoClick}
    />
  );

  const toggleDrawer1 = () => {
    setDrawerOpen1(!drawerOpen1);
    setTriggerFetch(true); // Trigger fetch when menu is opened
  }

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
   
  };

  const toggleDrawer2 = () => setDrawerOpen2(!drawerOpen2);
  const toggleDrawer3 = () => setDrawerOpen3(!drawerOpen3);
  const toggleDrawer4 = () => setDrawerOpen4(!drawerOpen4);
  const toggleDrawer5 = () => setDrawerOpen5(!drawerOpen5);
  const toggleDrawer6 = () => setDrawerOpen6(!drawerOpen6);
  const toggleDrawer7 = () => setDrawerOpen7(!drawerOpen7);

  //Navigate to the koffie kit page
  const handleKitClick = () => {
    navigate('/main');
  };

  const handleBeanClick = () => {
    navigate('/dashboard');
  };
const handleDoClick = () => {
  navigate('/goals');
}

const handleReportClick = () => {
  navigate('/bean-report');
}

const handleWorldClick = () => {
  navigate('/allkoffie');
}

const handleHistoryClick = () => {
  navigate('/chron');
}

const handleContractClick = () => {
  navigate('/contractdetails');
}

const handleBeanEconClick = () => {
  navigate('/beanlist');
}

const handleFAQClick = () => {
  navigate('/faq');
}

const handleFeedbackClick = () => {
  navigate('/feedback');
}

const handleNftClick = () => {
  navigate('/web3market');
}

const handleProfileClick = () => {
  navigate(`/userprof/${user.id}`); // Corrected the use of backticks
};

const handleRewardClick = () => {
  navigate('/reviews');
};


  const handleItemClick = () => {
    // Close the first drawer and open the second after a delay
    setDrawerOpen1(false);
    setTimeout(() => {
      handleReportClick();
    }, 300); // Delay to ensure the first drawer is closed before the second one opens
  };

  const handleItemClick1 = () => {
    // Close the first drawer and open the second after a delay
    setDrawerOpen1(false);
    setTimeout(() => {
      handleBeanClick();
    }, 300); // Delay to ensure the first drawer is closed before the second one opens
  }

  const handleItemClick3 = () => {
    // Close the first drawer and open the second after a delay
    setDrawerOpen1(false);
    setTimeout(() => {
     handleKitClick();
    }, 300); // Delay to ensure the first drawer is closed before the second one opens
  }

  const handleItemClick4 = () => {
    // Close the first drawer and open the second after a delay
    setDrawerOpen1(false);
    setTimeout(() => {
      handleDoClick();
    }, 300); // Delay to ensure the first drawer is closed before the second one opens
  }

  const handleItemClick5 = () => {
    // Close the first drawer and open the second after a delay
    setDrawerOpen(false);
    setTimeout(() => {
      handleWorldClick();
    }, 300); // Delay to ensure the first drawer is closed before the second one opens
  }

  const handleItemClick6 = () => {
    // Close the first drawer and open the second after a delay
   handleRewardClick();// Trigger fetch when menu is opened
    setDrawerOpen1(false);
    
    
  }

  const handleItemClick7 = () => {
    // Close the first drawer and open the second after a delay
    setDrawerOpen(false);
    setTimeout(() => {
      handleHistoryClick();
    }, 300); // Delay to ensure the first drawer is closed before the second one opens
  }

  const handleItemClick8 = () => {
    // Close the first drawer and open the second after a delay
    setDrawerOpen(false);
    setTimeout(() => {
      handleContractClick();
    }, 300); // Delay to ensure the first drawer is closed before the second one opens
  }

  const handleItemClick9 = () => {
    // Close the first drawer and open the second after a delay
    setDrawerOpen(false);
    setTimeout(() => {
      handleBeanEconClick();
    }, 300); // Delay to ensure the first drawer is closed before the second one opens
  }

  const handleItemClick10 = () => {
    // Close the first drawer and open the second after a delay
    setDrawerOpen(false);
    setTimeout(() => {
      handleFeedbackClick();
    }, 300); // Delay to ensure the first drawer is closed before the second one opens
  }

  const handleItemClick11 = () => {
    // Close the first drawer and open the second after a delay
    setDrawerOpen(false);
    setTimeout(() => {
      handleFAQClick();
    }, 300); // Delay to ensure the first drawer is closed before the second one opens
  }
  
  const handleItemClick12 = () => {
    // Close the first drawer and open the second after a delay
    setDrawerOpen(false);
    setTimeout(() => {
      handleNftClick();
    }, 300); // Delay to ensure the first drawer is closed before the second one opens
  }
 const handleItemClick13 = () => {
    // Close the first drawer and open the second after a delay
    setDrawerOpen1(false);
    setTimeout(() => {
      handleProfileClick();
    }, 300); // Delay to ensure the first drawer is closed before the second one opens
  }

 
  const { showloading, setShowLoading } = useState(false);

  const [showHeader, setShowHeader] = useState(true);
const [showFooter, setShowFooter] = useState(true);
const lastScrollY = useRef(0);
const childrenRef = useRef(null);
let lastScrollTop = 0;

const handleScroll = useCallback(() => {
  const currentScrollY = childrenRef.current.scrollTop;
  const threshold = 50; // Lowered threshold for more frequent updates

  if (Math.abs(lastScrollY.current - currentScrollY) >= threshold) {
    setShowHeader(currentScrollY < lastScrollY.current);
    setShowFooter(currentScrollY < lastScrollY.current);
    lastScrollY.current = currentScrollY;
  }
}, []);

useEffect(() => {
  const childrenElement = childrenRef.current;
  if (childrenElement) {
    childrenElement.addEventListener('scroll', handleScroll);
  }

  return () => {
    if (childrenElement) {
      childrenElement.removeEventListener('scroll', handleScroll);
    }
  };
}, [handleScroll]);

useEffect(() => {
  if (childrenRef.current) {
    childrenRef.current.scrollTop = 0;
  }
}, [children]);


  if (cartLoading) return <div></div>;
  if (cartError) return <div>Error: {cartError.message}</div>;
  
  const formatDigiBeans = (count) => {
    if (count >= 1000) {
      return `${count / 1000}k`;
    }
    return count;
  };

  if (userLoading) return <Typography></Typography>;
  if (userError) return <Typography>Error: {userError.message}</Typography>;



  return (
    
      <Container  maxWidth='lg' sx={{ padding: 0 }}>
        <Grid container spacing={0} sx={{ position: 'relative' }}>
          {!isMobile && (
            <Grid item xs={3} sx={{
              position: 'sticky',
              top: 0,
              height: 'calc(100vh - 64px)',
              overflowY: 'auto',
            }}>
              <LeftBar />
            </Grid>
          )}
          <Grid item xs={12} md={12} lg={6} sx={{ display: 'flex', flexDirection: 'column', height: '100vh', }}>
          {isMobile && showHeader && (
  <div className={classes.menuTransition}>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '0px solid lightgrey',
        paddingTop: '0px',
        paddingLeft: '0px', // Add padding to match the profile icon on the left
        paddingRight: '16px', // Add padding to match the profile icon on the right
      }}
    >
      {/* Left Menu (Profile or Menu Icon) */}
      <div className={classes.boxContainer}>
        <div >
          {userData && userData.userprof && userData.userprof.imageurl ? (
            
              <IconButton onClick={toggleDrawer}>
                <MenuIcon onClick={toggleDrawer} fontSize="large" />
              </IconButton>
            
          ) : (
            <Avatar
              alt="Nickname"
              src="{profileimage}"
              sx={{ width: 50, height: 50 }}
              onClick={handleClick}
              component={Link}
              to={`/userprof/${user.id}`}
            />
          )}
        </div>
      </div>

      {/* Center Menu (Logo and Beanout Chip) */}
      <div style={{ position: 'absolute', top: 8 , right: 200, justifyContent: 'center', alignItems: 'center' }}>
        {itemCount1 > 0 ? (
          <Chip
            component={Link}
            to="/bean-bag"
            label={`${itemCount1} Beanout`}
            icon={<img src={kcoin} alt="Kcoin" style={{ width: 15 }} />}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 1,
              borderRadius: 5,
            }}
          />
        ) : (
          <img
            src="https://storage.googleapis.com/app_darkendimg/assets/kland.png"
            alt="Koffie"
            style={{  width: 50, height: 50 }}
          />
        )}
      </div>

      {/* Right Menu (Shopping Cart, Brkoin, and Profile Icon) */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* Shopping Cart and Brkoin */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '16px', // Ensure the shopping cart and brkoin are further to the right
          }}
        >
          {itemCount > 0 ? (
            <React.Fragment>
              <IconButton component={Link} to="/bag" sx={{ position: 'relative' }}>
                <Badge
                  badgeContent={itemCount}
                  overlap="circular"
                  sx={{
                    '& .MuiBadge-badge': {
                      backgroundColor: '#ffc107', // Custom background color
                      color: '#000', // Custom text color
                      top: 0,
                      right: 0,
                      fontSize: '0.65rem',

                    },
                  }}
                >
                  <ShoppingCartIcon sx={{ color: '#077336' }} />
                </Badge>
              </IconButton>

              <Box
                component={Link}
                to="/bag"
                sx={{ display: 'flex', alignItems: 'center', ml: 1, textDecoration: 'none' }}
              >
                <Typography variant="caption" fontWeight={500} sx={{ color: '#352536', textDecoration: 'none' }}>
                  {formatDigiBeans(koffieItems.length * 4000)}
                </Typography>
                <animated.div style={styles}>
                  <img
                    src="https://storage.googleapis.com/app_darkendimg/assets/royal.png"
                    alt="Kcoin"
                    style={{ width: 20, marginRight: 5, marginBottom: 10 }}
                  />
                </animated.div>
              </Box>
            </React.Fragment>
          ) : null}
        </Box>

        {/* Profile Icon */}
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {userData && userData.userprof && userData?.userprof?.imageurl ? (
              <Avatar
                alt="Nickname"
                src={userData?.userprof?.imageurl}
                sx={{ width: 40, height: 40 }}
                onClick={toggleDrawer1}
              />
            ) : (
              <Avatar
                alt="Nickname"
                src="{profileimage}"
                sx={{ width: 40, height: 40 }}
                
                component={Link}
                
                onClick={toggleDrawer1}
              />
            )}
          </div>
        </Box>
      </div>
    </div>
  </div>
)}

           

            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={toggleDrawer}
              className={classes.drawer}
            >
              <Paper sx={{ width: 300, maxWidth: '100%' }}>
      <MenuList >
      <MenuItem>
          <ListItemIcon>
          <Box className={classes.boxContainer}>
      <img src='https://storage.googleapis.com/app_darkendimg/assets/kland.png' alt='menu' className={classes.logo} />
      <Box className={classes.ribbon}>
        <div  className={classes.ribbonText}>
          BETA
        </div>
        
      </Box>
      <Typography variant="body1" color='text.secondary' sx={{fontWeight: 700,  }}>
         KOFFIELAND™
          </Typography>
    </Box>
          
          </ListItemIcon>
         
         
        </MenuItem>
        <MenuItem onClick={handleItemClick8}>
          <ListItemIcon>
            <ReceiptOutlined fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Contracts List</ListItemText>
         
        </MenuItem>
        <MenuItem onClick={handleItemClick5}>
          <ListItemIcon>
            <PublicOutlined fontSize="medium" />
          </ListItemIcon>
          <ListItemText>World Koffie</ListItemText>
         
        </MenuItem>
        <MenuItem onClick={handleItemClick7}>
          <ListItemIcon>
            <HistoryEduOutlined fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Koffie History</ListItemText>
         
        </MenuItem>
        <MenuItem onClick={handleItemClick9}>
          <ListItemIcon>
            <BeanIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Bean Economics</ListItemText>
         
        </MenuItem>
        <MenuItem onClick={handleItemClick12}>
          <ListItemIcon>
            <Cloud fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Collect Beanheads </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleItemClick11}>
          <ListItemIcon>
            <LiveHelpOutlined fontSize="medium" />
          </ListItemIcon>
          <ListItemText>FAQ</ListItemText>
          
        </MenuItem>
       
        <Divider />
        <MenuItem onClick={handleItemClick10}>
          <ListItemIcon>
            <CommentOutlined fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Feedback </ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
           <Display />
          </ListItemIcon>
         
        </MenuItem>
      <MenuItem>
                   <ListItemText >
          <Typography variant="h6" color="text.secondary">
            <LogoutButton />
          </Typography>
          </ListItemText>
          
        </MenuItem>
      </MenuList>
    </Paper>
            </Drawer>
           
            <Drawer 
              anchor="right"
              open={drawerOpen1}
              onClose={toggleDrawer1}
              className={classes.drawer}
            >
               <Box sx={{ width: 350, padding: 0 , mt: 8}}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 0 }}>
                    <Avatar
                      alt={userData?.userprof?.Nickname}
                      src={userData?.userprof?.imageurl}
                      sx={{ width: 90, height: 90 }}
                      onClick={handleItemClick13}

                    />
                   
                  </Box>  
                  <Box sx={{ display: 'flex', justifyContent: 'center', ml: 0 }}>
                    <Typography variant="caption" sx={{ textAlign: 'center', fontWeight: 400 }}>
                    go to profile
                  </Typography>
                  </Box>
                  {/*go to user profile */}
                  
                  
                  
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <Typography variant="h5" sx={{fontWeight: 700}}>{userData?.userprof?.Nickname}</Typography>
                  </Box>
                  <Grid container spacing={2} sx={{ mb: 3 }}>
                  <Grid item xs={4}>
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} onClick={handleItemClick1}>
    <img src='https://storage.googleapis.com/app_darkendimg/assets/brkoin.png' alt="Kcoin" style={{ width: 20, marginBottom: 4 }} />
        <Typography variant="body1" fontWeight={700} >{userData?.userprof?.beanCount}</Typography>
        <Typography variant="body2" fontWeight={600} color='text.secondary'>Bean Bag</Typography>
  </Box>
</Grid>

<Grid item xs={4}>
 
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'  }} onClick={handleItemClick6}>
    <img src='https://storage.googleapis.com/app_darkendimg/assets/brkoin.png' alt="Kcoin" style={{ width: 20, marginBottom: 4 }} />
    <Typography variant="body1"><Rewards triggerFetch={triggerFetch} /></Typography>
    <Typography variant="body2" fontWeight={600} color='text.secondary'>Rewards</Typography>
  </Box>
  
</Grid>

<Grid item xs={4}>
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <img src='https://storage.googleapis.com/app_darkendimg/assets/brkoin.png' alt="Kcoin" style={{ width: 20, marginBottom: 4 }} />
    <Typography variant="body2" fontWeight={700} >{userData?.userprof?.beanCount}</Typography>
    <Typography variant="body1" fontWeight={600} color='text.secondary'>Digi Beans</Typography>
  </Box>
</Grid>

                  </Grid>
                 
                  <List sx={{ fontWeight: 700 }}>
      <ListItem button onClick={handleItemClick1}>
        <ListItemIcon>
          <Icon><ShoppingBagOutlined /></Icon>
        </ListItemIcon>
        <ListItemText 
          primary="Bean Bag" 
          primaryTypographyProps={{ variant: 'body1', fontWeight: 700 }}
        />
      </ListItem>

      <ListItem button onClick={handleItemClick3}>
        <ListItemIcon>
          <Icon><LocalCafeOutlined /></Icon>
        </ListItemIcon>
        <ListItemText 
          primary="Koffie Kit" 
          primaryTypographyProps={{ variant: 'body1', fontWeight: 700 }}
        />
      </ListItem>

      <ListItem button onClick={handleItemClick4}>
        <ListItemIcon>
          <Icon><ChecklistRtlOutlinedIcon /></Icon>
        </ListItemIcon>
        <ListItemText 
          primary="To Do List" 
          primaryTypographyProps={{ variant: 'body1', fontWeight: 700 }}
        />
      </ListItem>

      <ListItem button onClick={handleItemClick}>
        <ListItemIcon>
          <Icon><ListAltOutlined /></Icon>
        </ListItemIcon>
        <ListItemText 
          primary="Reports" 
          primaryTypographyProps={{ variant: 'body1', fontWeight: 700 }}
        />
      </ListItem>
    </List>
                </Box>
                
            </Drawer>
          
             {/* Second Drawer */}
             <Drawer
  anchor="left"
  open={drawerOpen2}
  onClose={toggleDrawer2}
  className={classes.drawer}
  sx={{ 
    height: '100vh', 
    zIndex: 1501,  // Set higher than the bottom navigation
    '& .MuiPaper-root': { 
      width: '100%', // Ensures the drawer covers the full height
      
    },
  }}
>
  <IconButton onClick={toggleDrawer2} sx={{ position: 'absolute', top: 0, left: 0 }}>
    <Close />
  </IconButton>
  <Box sx={{ display: 'flex', flexDirection: 'column', padding: 2, mt: 8 }}>
    <Logic />
  </Box>
</Drawer>


      {/* Start Dialog */}
      <Drawer
  anchor="left"
  open={drawerOpen3}
  onClose={toggleDrawer3}
  className={classes.drawer}
  sx={{ 
    height: '100vh', 
    zIndex: 1501,  // Set higher than the bottom navigation
    '& .MuiPaper-root': { 
      width: '100%', // Ensures the drawer covers the full height
      
    },
  }}
>
        <IconButton onClick={toggleDrawer3} sx={{  }}>
          <Close />
        </IconButton>
        {/* Contents of the second drawer */}
        <Box sx={{ width: 400, padding: 2, mt: 8 }}>
          <KoffieCalc />
        </Box>
      </Drawer>
     
      {/* Start Dialog4 */}
      
     
      {/* Start Dialog5 */}
      <Drawer
  anchor="left"
  open={drawerOpen5}
  onClose={toggleDrawer5}
  className={classes.drawer}
  sx={{ 
    height: '100vh', 
    zIndex: 1501,  // Set higher than the bottom navigation
    '& .MuiPaper-root': { 
      width: '100%', // Ensures the drawer covers the full height
      
    },
  }}
>
        <IconButton onClick={toggleDrawer5} sx={{ position: 'absolute', top: 0, left: 0 }}>
          <Close />
        </IconButton>
        {/* Contents of the second drawer */}
        <Box sx={{ width: 400, padding: 2, mt: 8 }}>
          <GoalComponent />
        </Box>
      </Drawer>
      <Drawer
  anchor="left"
  open={drawerOpen6}
  onClose={toggleDrawer6}
  className={classes.drawer}
  sx={{ 
    height: '100vh', 
    zIndex: 1501,  // Set higher than the bottom navigation
    '& .MuiPaper-root': { 
      width: '100%', // Ensures the drawer covers the full height
      
    },
  }}
>
        <IconButton onClick={toggleDrawer6} sx={{ position: 'absolute', top: 0, left: 0 }}>
          <Close />
        </IconButton>
        {/* Contents of the second drawer */}
        <Box sx={{ width: 400, padding: 2, mt: 8 }}>
          <BeanReport />
        </Box>
      </Drawer>
            {!isMobile && (
               <Box display="flex" justifyContent="space-around" className={classes.menuBox}>
     
               <Link to="/post"><Typography className={classes.menuItem}>Home</Typography></Link>
               <Link to="/serv"><Typography className={classes.menuItem}>Services</Typography></Link>
               <Link to="/beanwatch"><Typography className={classes.menuItem}>KaaF</Typography></Link>
               
               
                
              {itemCount > 0 ? (
            <React.Fragment>
            <IconButton component={Link} to="/bag" sx={{ position: 'relative' }}>
            <Badge
                badgeContent={itemCount}
                overlap="circular"
                sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: '#ffc107', // Custom background color  
                    color: '#000', // Custom text color
                  },
                }}
              >
                <ShoppingCartIcon sx={{ color: '#352536' }} />
              </Badge>
            </IconButton>
            
            <Box component={Link} to="/bag" sx={{ display: 'flex', alignItems: 'center', ml: 2, textDecoration: 'none' }}>
               
              <Typography variant="body2"  fontWeight={600} sx={{color: '#352536', textDecoration: 'none'}}>
              {formatDigiBeans(koffieItems.length * 4000)} 
              </Typography>
              <animated.div style={styles}>
            <img src='https://storage.googleapis.com/app_darkendimg/assets/brkoin.png' alt="Kcoin" style={{ width: 15, marginRight: 5, marginBottom: 10 }} />
            </animated.div>
            </Box>
          </React.Fragment>
         ) : (
          <>
          {userData && userData.userprof && userData?.userprof?.imageurl ? (
            <Avatar alt='Nickname' src={userData?.userprof?.imageurl} sx={{ width: 30, height: 30 }} onClick={handleClick} 
            component={Link} to={`/${userData.userprof.usertype}/${userData.userprof._id}`} />
          ) : (

           <Avatar alt='Nickname' src='{profileimage}' sx={{ width: 30, height: 30 }} onClick={handleClick} 
           component={Link} to={`/userprof/${user.id}`} /> 
          )}
           </>

         )}
               
             </Box>
           
            )}
           <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
    {isMobile && showHeader && (
      // YourHIe header component here
      <Typography variant="h6" fontWeight={700} sx={{color: '#352536', textDecoration: 'none'}}>
       
      </Typography>
    )}
    <Box 
      ref={childrenRef} 
      className={classes.hideScrollbar} 
      sx={{ 
        overflowY: 'auto', 
        flex: 1,
        WebkitOverflowScrolling: 'touch', // For smoother scrolling on iOS
      }}
    >
      {children}
    </Box>
    {isMobile && showFooter && (
      <Toe />
    )}
  </Box>
          </Grid>
          {!isMobile && (
            <Grid item xs={3} sx={{
              position: 'sticky',
              top: 0,
              height: 'calc(100vh - 64px)',
              overflowY: 'auto',
              scrollbarWidth: 'none',
              '-ms-overflow-style': 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}>
              <Grid container>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 2 }}>
                  <Link to="/feedback" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Button sx={{ borderRadius: 5, backgroundColor: '#3ff107', color: '#000', border: 'none', ":hover": { backgroundColor: '#ffc107' } }}>
                      FEEDBACK
                    </Button>
                  </Link>
                </Grid>
              </Grid>

              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ marginTop: '10px', margin: '10px', border: '1px solid #d1d1d1', borderRadius: 10 }}>
                  <AudioPlayerComponent />
                </div>
                <AiGen2 />
                <LatestPost />
                <Featured />
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    
  );
};

export default AppLayout;
